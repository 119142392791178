import api from './api'

export const fetchUser = () => api.get(`/users/me`)
export const fetchSpecifiedUser = (id) => api.get(`/users/${id}`)
export const updateUserInfo = (user) => api.patch(`/users/${user.id}`, user)
export const bindPhone = (data) => api.post(`/users/bind-phone`, data)
export const changePassword = (password, passwordConfirm) => api.post('/users/change-password', {
    password,
    passwordConfirm,
})

export const fetchRoles = (params) => api.get('/roles', { params })
export const promoteUser = (url) => api.post('/user/promote', {
    url
})
