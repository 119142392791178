import create from 'zustand'
import { persist } from 'zustand/middleware'
import produce from 'immer'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const cookieStorage = {
  getItem: (name) => {
    return cookies.get(name)
  },
  setItem: (name, value) => {
    cookies.set(name, value, { path: '/', sameSite: 'lax' })
  },
  removeItem: (name) => {
    return cookies.remove(name)
  }
}

export const useStore = create(persist(set => ({
  user: undefined,
  setUser: (user) => set({ user }),
  token: undefined,
  setToken: (token) => set({ token }),
  loading: false,
  setLoading: (loading) => set({ loading }),
}), {
  name: 'auth',
  getStorage: () => cookieStorage,
  serialize: _ => _,
  deserialize: _ => _,
}))

export const useCourseStore = create(set => ({
  expandedKeysMap: {},
  setExpandedKeys: (id, keys) => set(produce(state => {
    state.expandedKeysMap[id] = keys
  }))
}))

export const usePlayerStore = create(set => ({
  src: undefined,
  playing: false,
  play: (src) => set({
    src,
    playing: true
  }),
  stop: () => set({
    src: undefined,
    playing: false
  })
}))

export const usePlyrStore = create(set => ({
  isFullscreen: false,
  setIsfullscreen: (isFullscreen) => set({ isFullscreen }),
}))

export const useLoginStore = create(set => ({
  error: undefined,
  setError: (error) => set({ error }),
}))
