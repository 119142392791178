import 'react-app-polyfill/ie11'

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/zh-cn'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'virtual:windi.css'
import 'virtual:windi-devtools'
import '~antd/dist/antd.less'
import './index.css'
import './App.less'
import 'plyr/dist/plyr.css'
import 'react-chat-widget/lib/styles.css'
import 'rsuite-table/lib/less/index.less'

import 'typeface-roboto'
import 'typeface-roboto-slab'

dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.locale('zh-cn')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
