import React, { useEffect } from 'react'
import PuffLoader from 'react-spinners/BounceLoader'
import { isRN, rnSend } from '@/utils/rn'

export default function Loading() {
  
  useEffect(() => {
    rnSend('setLoading', { loading: true })

    return () => {
      rnSend('setLoading', { loading: false })
    }
  }, [])

  return (
    <div className="w-full h-screen flex items-center justify-center">
      {!isRN() && <PuffLoader color="#33BFA3" />}
    </div>
  )

}
