import Loading from '@/components/loading'
import React, { lazy, Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

const convertRoute = ({
  path,
  component: C,
  element,
  breadcrumb,
  children,
}) => ({
  path: path,
  element: (element || C) && (
    <Suspense fallback={<Loading />}>{element || <C />}</Suspense>
  ),
  breadcrumb,
  children: children?.map(convertRoute),
})

const mobileRoutes = [
  {
    path: 'content/:id',
    component: lazy(() => import('@/pages/Content')),
  },
  {
    path: 'courses/:courseId/contents/:id',
    component: lazy(() => import('@/pages/Content')),
    breadcrumb: '课程内容',
  },
  {
    path: 'practice/:id',
    component: lazy(() => import('@/pages/practice/view')),
  },
  {
    path: 'task/:id/preview',
    component: lazy(() => import('@/pages/task/preview')),
  },
  {
    path: 'questions/:id/preview',
    component: lazy(() => import('@/pages/task/preview')),
    breadcrumb: '题库预览',
  },
  {
    path: 'exam/:id/preview',
    component: lazy(() => import('@/pages/exam/preview')),
  },
  {
    path: 'exam/:id/attendance/:userId',
    component: lazy(() => import('@/pages/exam/attendance')),
  },
  {
    path: 'question/:id',
    component: lazy(() => import('@/pages/question/view')),
  },
  {
    path: 'classroom/:id/task/:task_id',
    component: lazy(() => import('@/pages/classroom/task')),
  },
  {
    path: 'classroom/:id/stat',
    component: lazy(() => import('@/pages/classroom/stat')),
  },
  {
    path: 'classroom/:id/course-analytics',
    component: lazy(() => import('@/pages/classroom/course-analytics')),
  },
  {
    path: 'classroom/:id/target',
    component: lazy(() => import('@/pages/classroom/target')),
  },
  {
    path: 'classrooms/:id/target',
    component: lazy(() => import('@/pages/classroom/target')),
    breadcrumb: '评价标准',
  },
  {
    path: 'classrooms/:id/courses/:courseId/tasks-selection',
    component: lazy(() => import('@/pages/classroom/tasks-selection')),
    breadcrumb: '必学任务',
  },
  {
    path: 'classroom/:id/task/:task_id/stat',
    breadcrumb: '任务统计',
    component: lazy(() => import('@/pages/classroom/task-stat')),
  },
  {
    path: 'classrooms/:id/task/:task_id/analysis',
    breadcrumb: '成绩分析',
    component: lazy(() => import('@/pages/classroom/exam-analysis')),
  },
  {
    path: 'classroom/:id/task/:task_id/submission/:submission_id',
    component: lazy(() => import('@/pages/classroom/task-submission')),
  },
  {
    path: 'resource/:id',
    component: lazy(() => import('@/pages/resource')),
  },
  {
    path: 'resources/:id',
    component: lazy(() => import('@/pages/resource')),
  },
  {
    path: 'notification/:id',
    component: lazy(() => import('@/pages/notification')),
  },
  {
    path: 'sso',
    component: lazy(() => import('@/pages/sso')),
    children: [
      {
        path: 'login',
        component: lazy(() => import('@/pages/sso/login')),
      },
      {
        path: 'callback',
        component: lazy(() => import('@/pages/sso/callback')),
      },
      {
        path: 'bind-wechat-callback',
        component: lazy(() => import('@/pages/sso/bind-wechat-callback')),
      },
    ],
  },
  {
    path: 'privacy',
    component: lazy(() => import('@/pages/privacy')),
  },
]

const pcRoutes = [
  {
    path: '',
    breadcrumb: '首页',
    component: lazy(() => import('@/pages/home')),
  },
  {
    path: 'download',
    breadcrumb: '课程APP',
    component: lazy(() => import('@/pages/download')),
  },
  {
    path: 'courses',
    breadcrumb: '数字教材',
    children: [
      {
        path: '',
        breadcrumb: '数字教材',
        component: lazy(() => import('@/pages/courses')),
      },
      {
        path: ':id',
        breadcrumb: '课程详情',
        component: lazy(() => import('@/pages/course')),
      },
    ],
  },
  {
    path: 'textbooks',
    children: [
      {
        path: '',
        breadcrumb: '教学资源',
        component: lazy(() => import('@/pages/textbook')),
      },
      {
        path: ':id',
        breadcrumb: '教材列表',
        component: lazy(() => import('@/pages/textbook/list')),
      },
      {
        path: ':cat_id/book/:id',
        breadcrumb: '教材详情',
        component: lazy(() => import('@/pages/textbook/detail')),
      },
      {
        path: ':cat_id/book/:id/preview/:resource_id',
        breadcrumb: '预览资源',
        component: lazy(() => import('@/pages/textbook/preview')),
      },
    ],
  },
  {
    path: 'resources',
    children: [
      {
        path: '',
        breadcrumb: '英海拾贝',
        component: lazy(() => import('@/pages/resource/list')),
      },
    ],
  },
  {
    path: 'questions',
    breadcrumb: '题库列表',
    component: lazy(() => import('@/pages/question')),
    children: [
      {
        path: 'public',
        breadcrumb: '公众题库',
        component: lazy(() => import('@/pages/question/public')),
      },
      {
        path: 'mine',
        breadcrumb: '我的题库',
        component: lazy(() => import('@/pages/question/mine')),
      },
      {
        path: 'fav',
        breadcrumb: '我收藏的',
        component: lazy(() => import('@/pages/question/fav')),
      },
      {
        path: 'university',
        breadcrumb: '校本题库',
        component: lazy(() => import('@/pages/question/university')),
      },
      {
        path: 'new',
        breadcrumb: '新建题目',
        component: lazy(() => import('@/pages/question-bank/new')),
      },
      {
        path: '',
        breadcrumb: '题库',
        element: <Navigate to="/questions/public" replace />,
      },
    ],
  },
  {
    path: 'questions/:id/preview',
    breadcrumb: '题库预览',
    component: lazy(() => import('@/pages/task/preview')),
  },
  {
    path: 'exams',
    children: [
      {
        path: 'templates',
        children: [
          {
            path: '',
            breadcrumb: '模板列表',
            component: lazy(() => import('@/pages/exam/template-list')),
          },
          {
            path: ':id',
            breadcrumb: '试卷模板',
            component: lazy(() => import('@/pages/exam/template')),
          },
        ],
      },
    ],
  },
  {
    path: 'classrooms',
    children: [
      {
        path: '',
        breadcrumb: '我的班级',
        component: lazy(() => import('@/pages/classes/list')),
      },
      {
        path: 'managed',
        breadcrumb: '我管理的',
        component: lazy(() => import('@/pages/classroom/managed')),
      },
      {
        path: ':id',
        breadcrumb: '班级详情',
        component: lazy(() => import('@/pages/classes/teacher-detail')),
      },
      {
        path: ':id/students',
        breadcrumb: '学生列表',
        component: lazy(() => import('@/pages/classes/students')),
      },
      {
        path: ':id/course-analytics',
        breadcrumb: '学习分析',
        component: lazy(() => import('@/pages/classroom/course-analytics')),
      },
      {
        path: ':id/summary',
        breadcrumb: '成绩汇总',
        component: lazy(() => import('@/pages/classes/summary')),
      },
      {
        path: ':id/summary/:userId',
        breadcrumb: '学习详情',
        component: lazy(() => import('@/pages/classroom/analytics')),
      },
      {
        path: ':id/stat',
        breadcrumb: '成绩详情',
        component: lazy(() => import('@/pages/classes/full-stat')),
      },
      {
        path: ':id/stat-alt',
        breadcrumb: '成绩详情',
        component: lazy(() => import('@/pages/classes/full-stat-alter')),
      },
      {
        path: ':id/vote',
        breadcrumb: '班级投票',
        component: lazy(() => import('@/pages/classes/vote')),
      },
    ],
  },
  {
    path: 'joined-classrooms/:id',
    breadcrumb: '班级详情',
    component: lazy(() => import('@/pages/classes/student-detail')),
  },
  {
    path: 'user',
    children: [
      {
        path: '',
        breadcrumb: '个人中心',
        component: lazy(() => import('@/pages/user')),
      },
      {
        path: 'progress',
        breadcrumb: '我的学习',
        component: lazy(() => import('@/pages/user/progress')),
      },
      {
        path: 'purchases',
        breadcrumb: '我的购买',
        component: lazy(() => import('@/pages/user/purchases')),
      },
    ],
  },
  {
    path: 'live',
    breadcrumb: '直播',
    component: lazy(() => import('@/pages/Live/temp')),
  },
  {
    path: 'question-bank',
    breadcrumb: '题库',
    component: lazy(() => import('@/pages/question-bank')),
  },
]

const mergedRouters = [
  ...mobileRoutes,
  {
    path: '/',
    breadcrumb: '首页',
    component: lazy(() => import('@/pages/app')),
    children: pcRoutes,
  },
]

export const routes = mergedRouters.map(convertRoute)
export function AppRoutes() {
  const elements = useRoutes(routes)
  return elements
}
