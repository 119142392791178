import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
import queryString from 'query-string'
import { useStore } from './stores'
import { fetchUser } from './services/user'
import { AppRoutes } from './routes'
import ScrollToTop from './components/scroll-to-top'
import { ErrorBoundary } from 'react-error-boundary'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <h2>系统出现了一个问题</h2>
      <pre style={{ wordWrap: 'break-word' }}>{error.message}</pre>
    </div>
  )
}

function App() {
  const { setUser, setToken, token } = useStore()

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    if (params.token) {
      setToken(params.token)
    }
  }, [])

  useEffect(() => {
    if (token) {
      fetchUser().then(setUser)
    }
  }, [token])

  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
        >
          <AppRoutes />
        </ErrorBoundary>
        <ScrollToTop />
      </Router>
    </ConfigProvider>
  )
}

export default App
