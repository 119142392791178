import axios from 'axios'
import { useStore } from '@/stores'

const api = axios.create({
  baseURL: '/api',
})

api.interceptors.request.use(
  function (config) {
    const token = useStore.getState().token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    if (!import.meta.env.PROD) {
      config.headers['X-IS-AGENT'] = 1
    }
    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

api.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (err) {
    return Promise.reject(err?.response?.data?.error ?? err)
  }
)

export default api
